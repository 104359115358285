// Generated by Framer (b5638f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["LNCyryaX2", "mYYn1BTbh"];

const serializationHash = "framer-2ur78"

const variantClassNames = {LNCyryaX2: "framer-v-1b2wiqz", mYYn1BTbh: "framer-v-bpzq3b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Mobile Menu [Open]": "mYYn1BTbh", "Mobile Menu": "LNCyryaX2"}

const getProps = ({click, height, iconColor, id, width, ...props}) => { return {...props, lYvpkC7IW: iconColor ?? props.lYvpkC7IW ?? "var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "LNCyryaX2", Xj6OpAmVP: click ?? props.Xj6OpAmVP} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;iconColor?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, lYvpkC7IW, Xj6OpAmVP, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LNCyryaX2", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapvehnvr = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Xj6OpAmVP) {const res = await Xj6OpAmVP(...args);
if (res === false) return false;}
setVariant("mYYn1BTbh")
})

const onTap1gexfqe = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Xj6OpAmVP) {const res = await Xj6OpAmVP(...args);
if (res === false) return false;}
setVariant("LNCyryaX2")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1b2wiqz", className, classNames)} data-framer-name={"Mobile Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"LNCyryaX2"} onTap={onTapvehnvr} ref={refBinding} style={{...style}} {...addPropertyOverrides({mYYn1BTbh: {"data-framer-name": "Mobile Menu [Open]", onTap: onTap1gexfqe}}, baseVariant, gestureVariant)}><motion.div className={"framer-1i8teke"} layoutDependency={layoutDependency} layoutId={"YFRwlZai6"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{mYYn1BTbh: {rotate: 45}}}/><motion.div className={"framer-1euco5r"} layoutDependency={layoutDependency} layoutId={"UjeX375m2"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{mYYn1BTbh: {rotate: -45}}}/><motion.div className={"framer-1wike7c"} layoutDependency={layoutDependency} layoutId={"soFmuWWF9"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 1, rotate: 0}} variants={{mYYn1BTbh: {opacity: 0, rotate: -45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2ur78.framer-1l2h51q, .framer-2ur78 .framer-1l2h51q { display: block; }", ".framer-2ur78.framer-1b2wiqz { cursor: pointer; height: 48px; position: relative; width: 48px; }", ".framer-2ur78 .framer-1i8teke { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 14px; width: 26px; }", ".framer-2ur78 .framer-1euco5r { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 22px; width: 26px; }", ".framer-2ur78 .framer-1wike7c { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 30px; width: 19px; }", ".framer-2ur78.framer-v-bpzq3b.framer-1b2wiqz { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 48px); }", ".framer-2ur78.framer-v-bpzq3b .framer-1i8teke { left: calc(50.00000000000002% - 26px / 2); top: 23px; }", ".framer-2ur78.framer-v-bpzq3b .framer-1euco5r { top: 23px; }", ".framer-2ur78.framer-v-bpzq3b .framer-1wike7c { bottom: 18px; height: unset; left: 13px; width: 13px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"mYYn1BTbh":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lYvpkC7IW":"iconColor","Xj6OpAmVP":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerE_JLhJ75K: React.ComponentType<Props> = withCSS(Component, css, "framer-2ur78") as typeof Component;
export default FramerE_JLhJ75K;

FramerE_JLhJ75K.displayName = "Menu/Hamburger Menu 3";

FramerE_JLhJ75K.defaultProps = {height: 48, width: 48};

addPropertyControls(FramerE_JLhJ75K, {variant: {options: ["LNCyryaX2", "mYYn1BTbh"], optionTitles: ["Mobile Menu", "Mobile Menu [Open]"], title: "Variant", type: ControlType.Enum}, lYvpkC7IW: {defaultValue: "var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11)) /* {\"name\":\"Gray 950\"} */", title: "Icon Color", type: ControlType.Color}, Xj6OpAmVP: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerE_JLhJ75K, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})